












import TitleBar from "@/components/TitleBar/index.vue";
import Vue from "vue";
import MyPrograms from "@/components/MyPrograms/index.vue";

export default Vue.extend({
  name: "AppProgramsController",
  components: {
    MyPrograms,
    TitleBar,
  },
});
